import React from 'react'
import './Header.css'

const Header = () => {
  return (
    <div className='Header'>
        <div>
            Products
        </div>
    </div>
  )
}

export default Header